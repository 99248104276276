import React from 'react';
import classNames from 'classnames';

import Icon from '@/Styles/Icons/Icon';

export default function Logo() {
  const logoCx = classNames('application-menu--logo');

  return (
    <div className={logoCx}>
      <div className='application-menu--logo--image'>
        <Icon icon='studio-logo' />
      </div>
    </div>
  );
}
